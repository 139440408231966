import React, { useEffect, useRef } from "react";
import CompanyDetails from "../component/CompanyDetails";
import ImagSlider from "../component/ImageSlider";
import FeatureProducts from "../component/FeatureProducts";
import Whatsapp from "../asset/pngImage/whatsapp.png";
import { Helmet } from "react-helmet";

function Home() {
  const sectionRef = useRef(null);
  useEffect(() => {
    if (window.location.hash === "#home") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const handleWhatsAppClick = () => {
    const phoneNumber = "9958344409"; // Replace with your phone number
    const message = encodeURIComponent("Hi, Zaya Dry Fruits!");
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="topMargin" ref={sectionRef}>

<Helmet>
        <title>
          Zaya Dryfruits | Best DryFruits, Nuts, Pulses, and Masale Online Shop{" "}
        </title>
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta
          name="title"
          content="Zaya Dryfruits | Best DryFruits, Nuts, Pulses, and Masale Online Shop"
        />
        <meta
          name="description"
          content="Relish the taste of finest quality of dry fruits, nuts and seeds, served at your door directly by Zaya Dry Fruits."
        />
        <meta
          name="keywords"
          content="Best Dryfruits, Nuts, Berries, Imported Nuts,Diwali Gift Pack, Best price guarantee, Cheapest dryfruits online shop"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Zaya Dryfruits | The Best Dry Fruits, Nuts, Pulses and Masale Online Shop | Wholesale Dealer "
        />
        <meta property="og:url" content="https://zaya.beaconlearninghub.com/" />
        <meta
          property="og:image"
          content="https://zaya.beaconlearninghub.com/assets/img/zaya-logo.png"
        />
        <meta
          property="og:description"
          content="Zaya Dry Fruits offer you Online Unbeatable Prices for best Quality of : Badam, Cashew whole, Raisins Indian, Prunes, Dry Cranberry, Dried Kiwi, Pulses, Masale and many more. "
        />
        <meta property="business:contact_data:country_name" content="India" />
        {/* JSON-LD script */}
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "companyname",
                "url": "https://zaya.beaconlearninghub.com/",
                "logo": "https://zaya.beaconlearninghub.com/assets/img/zaya-logo.png",
                "foundingDate": "2020",
                "sameAs": [
                    "https://www.facebook.com/ZayaDryFruits",
                    "https://twitter.com/ZayaDryFruits",
                    "https://www.instagram.com/zayadryfruits/",
                    "https://in.pinterest.com/ZAYADryFruits/"
                ]
            }
          `}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-8EXFWPNHC8');
          `}
        </script>
        <script>
          {`{ window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'UA-180675332-2');}`}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
        ></script>
        <script>
          {`{  window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'AW-353927359');}`}
        </script>
      </Helmet>
      <div>
        <ImagSlider />
      </div>
      <div>
        <FeatureProducts />
      </div>
      <div>
        <CompanyDetails />
      </div>
      <div className="whatsappButtonContainer">
        <img
          onClick={handleWhatsAppClick}
          alt="Whatsapp"
          src={Whatsapp}
          loading="lazy"
          className="card-img-top"
          style={{
            height: "100%",
            resizeMode: "contain",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}

export default Home;

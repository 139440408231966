import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URL, ImgURL } from "../url/api";
import Cookies from "js-cookie";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";
import almond from "../asset/pngImage/almond.png";

const SingelProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [Token, setToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idVariants, setIdVariants] = useState("");
  const [data, setData] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const currentPageUrl = window.location.href;
  useEffect(() => {
    checkLogin();
    if (id) {
      fetchData();
    }
  }, [id]);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const handleImageClick = (path) => {
    setSelectedImage(path);
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      fetch(`${URL}/getProductById/${id}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((res) => res.text())
        .then((response) => {
          const result = JSON.parse(response);
          setData(result.data);
          setSelectedImage(result?.data?.images[0]);
          setIdVariants(result.data.variants[0].id);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(idVariants));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          getCart();
          showToast(newData.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        newData.data.cart.map((item) => {
          return dispatch(refreshCart(item));
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div
      // className="container"
      style={{
        flex: 1,
        marginTop: 75,
        marginRight: 10,
        marginLeft: 10,
      }}
      className="row d-flex"
    >
      <Helmet>
        <title>{data?.name}</title>
        <meta name="Description" content={data?.meta_desc} />
        <meta name="KeyWords" content={data?.meta_keyword} />
        <meta property="og:title" content={data?.name} />
        <meta property="og:description" content={data?.meta_desc} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta property="og:image" content={`${ImgURL}${data.img}`} />
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="product" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-8EXFWPNHC8');
            `}
        </script>
        <script>
          {`{ window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'UA-180675332-2');}`}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
        ></script>
        <script>
          {`{  window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());

              gtag('config', 'AW-353927359');}`}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "description": "${data?.description || ""}",
              "name": "${data?.name || ""}",
              "image": "${ImgURL}${data.img || ""}",
              "url": "${currentPageUrl || ""}",
              "sku": "${data?.sku_code || ""}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.2",
                "reviewCount": "276"
              },
              "offers": {
                "@type": "Offer",
                "url": "${currentPageUrl || ""}",
                "availability": "http://schema.org/InStock",
                "price": "${
                  data?.variants && data.variants.length > 0
                    ? data.variants[0].sp
                    : ""
                }",
                "priceCurrency": "INR"
              }
            }
          `}
        </script>
      </Helmet>
      <div
        className="col-md-6 d-flex"
        style={{ marginTop: 15, marginBottom: 20 }}
      >
        {data?.images?.length > 0 ? (
          <div className="row singaleContainer">
            <div className="col-md-4 multiImage">
              {data?.images?.map((image) => (
                <img
                  key={image.id}
                  src={`${ImgURL}${image?.path}`}
                  alt={image.alt_text}
                  loading="lazy"
                  onError={handleImageError}
                  onMouseEnter={() => handleImageClick(image)}
                  style={{
                    cursor: "pointer",
                    margin: "5px",
                    border:
                      selectedImage?.id === image?.id
                        ? "2px solid #538cee"
                        : "1px solid #ccc",
                    borderRadius: "10px",
                    height: 80,
                    width: "70%",
                  }}
                />
              ))}
            </div>
            <div className="col-md-8" style={{ flex: 1 }}>
              <img
                key={selectedImage?.id}
                className="image-hover"
                alt={selectedImage?.alt_text}
                loading="lazy"
                onError={handleImageError}
                src={`${ImgURL}${selectedImage?.path}`}
                style={{
                  height: 450,
                  resizeMode: "cover",
                  width: "90%",
                  marginTop: 5,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="col-md-6" style={{ flex: 1 }}>
            <img
              key={selectedImage?.id}
              className="image-hover"
              alt={data.name}
              loading="lazy"
              onError={handleImageError}
              src={`${ImgURL}${data?.img}`}
              style={{
                height: 450,
                resizeMode: "cover",
                width: "90%",
                marginTop: 5,
              }}
            />
          </div>
        )}
      </div>
      <div
        className="col-md-6"
        style={{
          marginTop: 35,
          textAlign: "justify",
        }}
      >
        {data.name !== null && data.name !== "" ? (
          <>
            <h6 className="textColor">Name : </h6>
            <h1
              style={{
                fontSize: 18,
                marginLeft: 8,
                fontWeight: "600",
              }}
            >
              {data.name}
            </h1>
          </>
        ) : null}
        <div className="horizontalContainer">
          {data?.variants?.map((item, index) => {
            let calculate = (item.sp / item.mrp) * 100;
            let Percentage = 100 - calculate;
            return (
              <div
                role="button"
                key={index}
                className={
                  item.id === idVariants
                    ? "itemColor image-hover"
                    : "item image-hover"
                }
                onClick={() => {
                  setIdVariants(item.id);
                }}
              >
                {item.sp < item.mrp ? (
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#538cee",
                      borderBottomRightRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  >
                    <p
                      className="fw-bold pTag"
                      style={{
                        fontSize: 10,
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {Percentage.toFixed(2)}% Off
                    </p>
                  </div>
                ) : null}
                <div>
                  <p className="pTag" style={{ fontSize: 14, marginTop: 6 }}>
                    {item.size}
                  </p>
                </div>
                <div
                  className="horizontalContainer"
                  style={{
                    marginTop: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.mrp > item.sp ? (
                    <>
                      <p
                        className="pTag"
                        style={{ fontSize: 10, marginRight: 2 }}
                      >
                        <del>₹{item.mrp}</del>
                      </p>
                      <p
                        className="pTag"
                        style={{
                          fontSize: 12,
                          marginLeft: 2,
                          fontWeight: "bold",
                        }}
                      >
                        ₹{item.sp}
                      </p>
                    </>
                  ) : (
                    <p
                      className="pTag"
                      style={{
                        fontSize: 12,
                        marginLeft: 2,
                        fontWeight: "bold",
                      }}
                    >
                      ₹{item.sp}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ marginBottom: 10 }}>
          {!isLoggedIn ? (
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={() => {
                updateCart();
              }}
            >
              Add
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-success"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Add
            </button>
          )}
        </div>
        {data.description !== null && data.description !== "" ? (
          <>
            <h6 className="textColor">Product description : </h6>
            <p
              style={{ marginLeft: 10, marginRight: 10, textAlign: "justifiy" }}
            >
              {data.description}
            </p>
          </>
        ) : null}
        {data?.brand?.name !== null && data?.brand?.name !== "" ? (
          <>
            <h6 className="textColor">Brand : </h6>
            <p style={{ marginLeft: 10 }}>{data?.brand?.name}</p>
          </>
        ) : null}
        {data.manufacturer !== null && data.manufacturer !== "" ? (
          <>
            <h6 className="textColor">Manufacturer : </h6>
            <p style={{ marginLeft: 10 }}>{data.manufacturer}</p>
          </>
        ) : null}
        {data.origin_country !== null && data.origin_country !== "" ? (
          <>
            <h6 className="textColor">Country of origin : </h6>
            <p style={{ marginLeft: 10 }}>{data.origin_country}</p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SingelProducts;

import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const OrderDetails = () => {
  const items = useSelector((state) => state.order);
  const details = items?.data?.data;
  return (
    <div className="container topMargin">
      <div className="row" style={{justifyContent:'center'}}>
        <div className="col-md-6 card p-2 my-2">
          <label>OrderDetails - {`Order Id` + ` #`+ details?.id}</label>
          <p className="pTag">{details?.contact_person}</p>
          <p className="pTag">
            {details?.house_no}, {details?.locality}, {details?.city},{" "}
            {details?.pincode}, {details?.state}
          </p>
          <p className="pTag">{details?.contact_no}</p>
          <hr />
          <div className="orderDetailsStyle">
            <p> Discount</p>
            <p>₹{details?.discount}</p>
          </div>
          <div className="orderDetailsStyle">
            <p> Order Amount</p>
            <p>₹{details?.sub_total}</p>
          </div>
          <div className="orderDetailsStyle">
            <p> Delivery Charges</p>
            <p>₹{details?.delivery_charges}</p>
          </div>
          <div className="orderDetailsStyle">
            <p> Total Amount</p>
            <p>₹{details?.order_total}</p>
          </div>
          <div className="orderDetailsStyle">
            <p>savings</p>
            <p>₹{details?.total_savings}</p>
          </div>
          <hr />
          <div className="orderDetailsStyle">
            <p>Pay Amount</p>
            <p>
              ₹{details?.payable_amt}
              {`  `}
              {`(` + details?.pay_mode + `)`}
            </p>
          </div>
          <hr />
          <div className="row my-2">
            <div className="col-md">{details?.order_notes}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;

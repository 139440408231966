import React, { useState, useEffect } from "react";
import logo from "../asset/pngImage/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Cart from "../page/Cart";
import { useSelector, useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { URL } from "../url/api";

export default function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("q");
  const [searchTerm, setSearchTerm] = useState("");
  const count = useSelector((state) => state.product.data);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    checkLogin();
    return () => {
      checkLogin();
    };
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        getCartData(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        newData.data.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        if (newData?.data?.cart?.length === "0") {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const cookieValue = Cookies.get("user_session");
  const handleKeyPress = (event) => {
    console.log("enter");
    if (event.key === "Enter") {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };
  const handleSearch = () => {
    // Navigate to the search results page with the search term as a query parameter
    navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
  };
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ backgroundColor: "#efe9e3" }}
      >
        <div className="container-fluid">
          <a href={"/"}>
            <img
              src={logo}
              alt={"ZayaDryFruits"}
              loading="lazy"
              style={{ height: "100%", width: "70%" }}
            />
          </a>
          <div className="searchInput_fild">
            {search ? null : (
              <div className="col-auto my-2 mx-2" id="searchDetails">
                <div className="input-group">
                  {/* <div className="input-group-text">@</div> */}
                  <input
                    type="search"
                    placeholder="Search"
                    className="form-control"
                    id="autoSizingInputGroup"
                    value={searchTerm}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSearch();
                      setNavbarCollapsed(true);
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* <button
            className="navbar-toggler"
            type="button"
            onClick={() => setNavbarCollapsed(!navbarCollapsed)}
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <button
            className="btn btn_offcanvas"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
            onClick={() => setNavbarCollapsed(!navbarCollapsed)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`offcanvas offcanvas-start  ${
              navbarCollapsed ? "" : "show"
            }`}
            style={{ width: "75%" }}
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            {/* <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                Backdroped with scrolling
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="offcanvas-body">
              <ul
                className="navbar-nav me-auto mb-2 mb-lg-0"
                style={{ marginLeft: 20 }}
              >
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.title}
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/grocery/${"10"}/${"Ayurveda"}`}
                    // to="/shop/1"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.Shop}
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/Khari-Baoli-Dry-Fruits-Rate-List-Today`}
                    // to="/shop/1"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.All}
                  </Link>
                </li>
              </ul>
              <div className="searchInput_fild1">
                {search ? null : (
                  <div className="col-auto my-2 mx-2" id="searchDetails">
                    <div className="input-group">
                      {/* <div className="input-group-text">@</div> */}
                      <input
                        type="search"
                        placeholder="Search"
                        className="form-control"
                        id="autoSizingInputGroup"
                        value={searchTerm}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleSearch();
                          setNavbarCollapsed(true);
                        }}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ alignItems: "center", alignSelf: "center" }}>
                {cookieValue == null ? (
                  <Link
                    className="nav-link "
                    to="/login"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    <button type="button" className="btn btn-primary m-2">
                      login/Register
                    </button>
                  </Link>
                ) : (
                  <Link
                    className="nav-link "
                    to="/profile"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    <i className="fa fa-user-circle my-icon" />
                  </Link>
                )}
              </div>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <i
                  className="fa-solid fa-bag-shopping my-icon"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBackdrop"
                  aria-controls="offcanvasWithBackdrop"
                  role="button"
                />
                {count?.length > 0 ? (
                  <p className="cart">{count?.length}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Cart />
    </div>
  );
}

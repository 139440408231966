import React, { useState, useEffect } from "react";
import { URL, ImgURL } from "../url/api";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { OrderId } from "../reduxSetup/slices/orderIDSlice";
import ActivityIndicator from "../component/ActivityIndicator";
import { clearAllData } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";
import almond from "../asset/pngImage/almond.png";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [Token, setToken] = useState("");
  const [payMode, setPayMode] = useState("cod");
  const [promo, setPromoCode] = useState("");
  const [instructions, setInstructions] = useState("");
  const [addressUser, setAddressUser] = useState("");
  const [totalCartDetails, setTotalCartDetails] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    checkLogin();
    userAddress();
  }, [id]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      // script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.src = src;
      // script.async = true;
      script.onload = () => {
        // Razorpay script has loaded, you can now create payment instances
        resolve(true);
      };
      script.onerror = () => {
        // Razorpay script has loaded, you can now create payment instances
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        calculateCart(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const userAddress = async () => {
    try {
      const userAddress = await Cookies.get("user_address");
      setAddressUser(JSON.parse(userAddress));
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const calculateCart = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var formdata = new FormData();
    formdata.append("pay_mode", payMode);
    formdata.append("address_id", id);
    formdata.append("use_wallet", "0");
    formdata.append("coupon_code", promo);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/calculateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setTotalCartDetails(newData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handalCheckCoupon = (e) => {
    setPromoCode(e.target.value);
  };
  const handalDeliveryInstructions = (e) => {
    setInstructions(e.target.value);
  };
  const checkCoupon = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("coupon_code", promo);
    formdata.append("pay_mode", payMode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/checkCoupon`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultResponse = JSON.parse(result);
        if (resultResponse.error === false) {
          calculateCart(Token);
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const createOrder = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("pay_mode", payMode);
    formdata.append("address_id", id);
    formdata.append("use_wallet", "0");
    formdata.append("coupon_code", promo);
    formdata.append("order_notes", instructions);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/createOrder`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData?.data?.pay_mode === "cod") {
          setInstructions("");
          setPayMode("cod")
          dispatch(OrderId(newData));
          dispatch(clearAllData());
          navigate("/orderDetails", { replace: true });
        } else {
          const order_id = newData?.data?.pg_order_id;
          const razorPay_id = newData?.data?.pg_key;
          const check_id = newData?.data?.id;
          // setCheckPayment_Id(newData?.data?.id);
          navigate("/orderDetails", { replace: true });
          dispatch(OrderId(newData));
          dispatch(clearAllData());
          handlePayment(order_id, razorPay_id, check_id);
        }
        showToast(newData.msg);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  const handlePayment = async (order_id, razorPay_id, check_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK faild to load. Are you online ?");
      return;
    }
    var options = {
      key: razorPay_id, // Enter the Key ID generated from the Dashboardcurrency: "INR",
      name: "Zaya Dry Fruits", //your business name
      description: "Test Transaction",
      image: "https://zaya.beaconlearninghub.com/assets/img/zaya-logo.png",
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      handler: (response) => {
        console.log("response::::::::", response);
        // Handle the payment response
        if (response.razorpay_payment_id) {
          // Payment successful
          checkPayment(check_id);
          console.log("Payment Successful", response.razorpay_payment_id);
          // You can perform additional actions here, such as updating the UI or sending a confirmation email
        } else {
          // Payment failed or cancelled
          console.log("Payment Failed");
          // You can show an error message or perform any other necessary actions
        }
      },
    };

    const paymentInstance = new window.Razorpay(options);
    paymentInstance.open();
  };
  const checkPayment = (check_id) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}/checkPayment/${check_id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          navigate("/orderDetails", { replace: true });
          setPayMode('cod')
          showToast(newData.msg);
        } else {
          showToastErr(newData.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="row container-fluid topMargin">
      <Helmet>
        <title>Check Out</title>
      </Helmet>
      <div className="col-md-8">
        {totalCartDetails?.cart?.map((item) => {
          return (
            <div
              className="card"
              style={{ flexDirection: "row", marginTop: 6 }}
            >
              <div className="col-md-2 ">
                <img
                  key={item.id}
                  alt={item.product.name}
                  src={`${ImgURL}${item.product.img}`}
                  loading="lazy"
                  onError={handleImageError}
                  className="card-img-top mx-auto"
                  style={{
                    height: "60px",
                    resizeMode: "center",
                    width: "80px",
                    margin: 2,
                  }}
                />
              </div>
              <div className="col-md-7 ">
                <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                  {item.product.name}
                </p>
                <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                  Quantity:{item.qty}
                </p>
              </div>
              <div className="col-md ">
                <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                  Selling Price:₹{item.variant.sp}
                </p>
                <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                  Size:{item.variant.size}
                </p>
              </div>
            </div>
          );
        })}
        <div
          className="card my-2"
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            padding: 8,
          }}
        >
          <p className="fw-bold pTag" style={{ fontSize: 14 }}>
            Total Items:{totalCartDetails.item_count}
          </p>
          <p className="fw-bold pTag" style={{ fontSize: 14 }}>
            Total Amount: ₹{totalCartDetails.total}
          </p>
        </div>
      </div>
      <div className="col-md  my-2">
        {/* <div className="row my-2"> */}
        <label className="col-md-7"> Address</label>
        <div className="col-md">
          {addressUser?.contact_person},{addressUser.house_no},
          {addressUser.locality}
        </div>
        <div className="col-md">
          {addressUser?.city},{addressUser.pincode},{addressUser.state}
        </div>
        <div className="col-md">{addressUser?.contact_no}</div>
        {/* </div> */}
        <div className="card p-2 my-2">
          <div className="row my-2">
            <div className="col-md-7"> Discount</div>
            <div className="col-md">₹{totalCartDetails?.discount}</div>
          </div>
          <div className="row my-2">
            <div className="col-md-7"> Total Amount</div>
            <div className="col-md">₹{totalCartDetails?.total}</div>
          </div>
          <div className="row my-2">
            <div className="col-md-7"> Delivery Charges</div>
            <div className="col-md">₹{totalCartDetails?.delivery_charges}</div>
          </div>
          <div className="row my-2">
            <div className="col-md-7">savings</div>
            <div className="col-md">₹{totalCartDetails?.savings}</div>
          </div>
          <div className="row my-2">
            <div className="col-md-7">Used Wallet</div>
            <div className="col-md">₹{totalCartDetails?.used_wallet}</div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-7"> Payable Amount</div>
          <div className="col-md">₹{totalCartDetails?.payable_amt}</div>
        </div>
        <div className="row mx-2 my-2">
          <div className="col d-flex mx-2">
            <div className="form-check">
              <input
                type="radio"
                value="cod"
                checked={payMode === "cod"}
                onChange={() => {
                  setPayMode("cod");
                }}
              />{" "}
              <label>cod</label>
            </div>
            <div className="form-check mx-2">
              <input
                type="radio"
                value="online"
                checked={payMode === "online"}
                onChange={() => {
                  setPayMode("online");
                }}
              />{" "}
              <label>online</label>
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              id="formGroupExampleInput2"
              placeholder="PROMO CODE"
              onChange={handalCheckCoupon}
            />
          </div>
          <div className="col-md">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => {
                checkCoupon();
              }}
            >
              Apply
            </button>
          </div>
          <div className="col-md-9 my-4">
            <input
              type="text"
              className="form-control"
              id="formGroupExampleInput2"
              placeholder="Give delivery Instructions"
              onChange={handalDeliveryInstructions}
            />
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            style={{ marginTop: 15, marginLeft: 8, marginRight: 8 }}
            onClick={() => {
              createOrder();
            }}
          >
            {payMode === "cod" ? "Place Order" : "Pay Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

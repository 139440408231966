import "./App.css";
import Home from "./page/Home";
import Cart from "./page/Cart";
// import Products from "./page/Products";
import SingelProducts from "./page/SingelProducts";
import Shop from "./page/Shop";
import SubCategories from "./component/SubCategories";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Login from "./page/Login";
import Search from "./page/Search";
import About from "./ourPolicy/About";
import RedirectOldProductUrl from "./component/RedirectOldProductUrl";
import HoleSale from "./page/HoleSale";
import Contact from "./ourPolicy/Contact";
import TermCondition from "./ourPolicy/Term&Condition";
import ShippingPolicy from "./ourPolicy/ShippingPolicy";
import PrivacyPolicy from "./ourPolicy/PrivacyPolicy";
import Checkout from "./page/Checkout";
import OrderDetails from "./component/OrderDetails";
import Profile from "./page/Profile";
import Order from "./MyOrders/order";
import Address from "./MyAddress/newAddress";
import OrderPlaced from "./page/OrderPlaced";
import PageNotFound from "./page/PageNotFound";
// import { ThemeProvider } from "styled-components";
// import { GlobalStyle } from "./GlobalStyle";
function App() {
  // const theme = {
  //   colors: {
  //     heading: "rgb(24 24 29)",
  //     text: "rgb(24 24 29)",
  //     btn: " #825d37",
  //     helper: "#efe9e3",
  //     shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
  //   },
  //   media: {
  //     mobile: "768px",
  //     tab: "998px",
  //   },
  // };
  return (
    // <ThemeProvider theme={theme}>
    // <GlobalStyle />
    <Router>
      <Header
        title={"Home"}
        Shop={"Our Stores"}
        DryFruits={"Dry Fruits"}
        Nuts={"Nuts"}
        Seeds={"Seeds"}
        Masale={"Masale"}
        All={"Wholesale Price"}
      />
      <Routes>
        <Route
          path="/product-details.php"
          element={<RedirectOldProductUrl />}
        />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/grocery/:id/:itemName" exact element={<Shop />} />
        <Route
          path="/product/:id/:itemName"
          exact
          element={<SingelProducts />}
        />
        <Route
          path="/category/:id/:itemName"
          exact
          element={<SubCategories />}
        />

        <Route
          path="/category/:id/:itemName/:subcategoryId/:subcategoryName"
          exact
          element={<SubCategories />}
        />
        <Route path="/cart" exact element={<Cart />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/search" exact element={<Search />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} />
        {/* <Route path="/products" exact element={<Products />} /> */}
        <Route path="/term&Condition" exact element={<TermCondition />} />
        <Route
          path="/shipping&refundPolicy"
          exact
          element={<ShippingPolicy />}
        />
        <Route path="/Privacy-Policy" exact element={<PrivacyPolicy />} />
        <Route path="/checkout/:id" exact element={<Checkout />} />
        <Route path="/orderDetails" exact element={<OrderDetails />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/orders" exact element={<Order />} />
        <Route path="/OrderPlaced/:id" exact element={<OrderPlaced />} />
        <Route path="/address" exact element={<Address />} />
        <Route
          path="/Khari-Baoli-Dry-Fruits-Rate-List-Today"
          exact
          element={<HoleSale />}
        />
      </Routes>
      <Footer className="fixed-footer" />
    </Router>
    // </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { URL, ImgURL } from "../url/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItemToCart,
  reduceItemToCart,
  addProducts,
} from "../reduxSetup/slices/productsSlice";
import almond from "../asset/pngImage/almond.png";

const Cart = () => {
  const data = useSelector((state) => state.product.data);
  const dispatch = useDispatch();
  const min_order = "500";
  // const [data, setData] = useState([]);
  const [countData, setCountData] = useState("");
  const [Token, setToken] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [addressSet, setAddressSet] = useState(false);
  const [add_Address, setAdd_Address] = useState(false);
  const [update_Address, setUpdate_Address] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [addressId, setAddressID] = useState("");
  useEffect(() => {
    checkLogin();
    Total_price();
  }, [data]);
  const Total_price = () => {
    let arr = [];
    if (data && data.length) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const qty_product = element?.qty;
        const SP_price = element?.variant?.sp;
        const price_SP = SP_price * qty_product;
        arr.push(price_SP);
        const sum_SP = eval(arr.join("+"));
        setCountData(sum_SP);
      }
    }
  };
  //show toast
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  //use all input field handle
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
    }
  };
  const houseInputChange = (e) => {
    setHouseNumber(e.target.value);
  };
  const nearInputChange = (e) => {
    setLocality(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setZip(inputPhoneNumber);
    }
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  // this is use to check login to user login or not
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  // this is use to increment and decrement data to get cart data
  const getCartDataforUpdate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        console.log("newData::::::::", newData);
        // setData(newData.data.cart);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // this function  use to increment items quantity
  function Increment(item) {
    const variantID = item.variant_id;
    let newCount = item.qty + 1;
    updateCart(variantID, newCount);
  }
  // this function  use to Decrement items quantity
  function Decrement(item) {
    const variantID = item.variant_id;
    if (item.qty >= 1) {
      let newCount = item.qty - 1;
      updateCart(variantID, newCount);
    }
  }
  // this is use to increment and decrement data to update cart
  const updateCart = (variantID, newCount) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(variantID));
    formdata.append("qty", newCount);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getCartDataforUpdate();
      })
      .catch((error) => console.log("error", error));
  };
  // Get user Address
  const getCustomerAddresses = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCustomerAddresses`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        console.log("userData::::::::", userData.data[0].id);
        setAddressData(userData.data);
        setAddressID(userData.data[0].id);
        Cookies.set("user_address", JSON.stringify(userData.data[0]));
      })
      .catch((error) => console.log("error", error));
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  // Add  New Customer Address
  const addCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("house_no", houseNumber);
    formdata.append("locality", locality);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", zip);
    formdata.append("contact_person", name);
    formdata.append("contact_no", phoneNumber);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/addCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        console.log("userData::::::::", userData);
        if (userData.error === false) {
          getCustomerAddresses();
          setName();
          setPhoneNumber();
          setHouseNumber();
          setLocality();
          setCity();
          setState();
          setZip();
          setAdd_Address(false);
          setAddressSet(true);
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handaleInput = () => {
    setName("");
    setPhoneNumber("");
    setHouseNumber("");
    setLocality("");
    setCity("");
    setState("");
    setZip("");
  };
  // Delete Customer Address
  const deleteCustomerAddress = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/deleteCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        getCustomerAddresses();
        showToast(userData.msg);
      })
      .catch((error) => console.log("error", error));
  };
  const handaleEditeAddress = (item) => {
    if (item) {
      setAddressID(item.id);
      setName(item.contact_person);
      setPhoneNumber(item.contact_no);
      setHouseNumber(item.house_no);
      setLocality(item.locality);
      setCity(item.city);
      setState(item.state);
      setZip(item.pincode);
      setAdd_Address(true);
      setAddressSet(false);
      setUpdate_Address(true);
    }
  };

  // Update Customer Address
  const updateCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", addressId);
    formdata.append("house_no", houseNumber);
    formdata.append("locality", locality);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", zip);
    formdata.append("contact_person", name);
    formdata.append("contact_no", phoneNumber);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/updateCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.error === false) {
          getCustomerAddresses();
          setAdd_Address(false);
          setAddressSet(true);
          setUpdate_Address(false);
          setAddressID("");
          setName("");
          setPhoneNumber("");
          setHouseNumber("");
          setLocality("");
          setCity("");
          setState("");
          setZip("");
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasWithBackdrop"
      aria-labelledby="offcanvasWithBackdropLabel"
    >
      {/* initial  add_Address === false  then  show my cart data if add_Address === true  then show Add Address */}
      {add_Address === false ? (
        <>
          {/* initial  addressSet === false  then  show my cart data if addressSet === true  then show address details*/}
          {addressSet === false ? (
            <>
              {/* this is cart code */}
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                  My Cart
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="offcanvas-body"
                style={{
                  marginBottom: 50,
                }}
              >
                {data?.map((item, index) => {
                  return (
                    <div
                      className="row-md-4 card "
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        className="col-md-4 "
                        style={{ textAlign: "center"}}
                      >
                        <img
                          key={item.id}
                          alt={item?.product?.name}
                          loading="lazy"
                          onError={handleImageError}
                          src={`${ImgURL}${item.product.img}`}
                          className="card-img-top mx-auto"
                          style={{
                            height: "80px",
                            resizeMode: "center",
                            width: "80px",
                            margin: 2,
                          }}
                        />
                      </div>
                      <div
                        className="col-md-4"
                        style={{
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                      >
                        <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                          {item.product.name}
                        </p>
                        <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                          {item.variant.size}
                        </p>
                        {item.variant.mrp !== item.variant.sp ? (
                          <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                            <del>₹{item.variant.mrp}</del>₹{item.variant.sp}
                          </p>
                        ) : (
                          <p className="fw-bold pTag" style={{ fontSize: 11 }}>
                            ₹{item.variant.sp}
                          </p>
                        )}
                      </div>
                      <div
                        className="col-md-4"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                          style={{marginRight:6}}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              if (item.qty > 1) {
                                dispatch(reduceItemToCart(item));
                                Decrement(item);
                              } else {
                                dispatch(removeItemToCart(index));
                                Decrement(item);
                              }
                            }}
                          >
                            -
                          </button>
                          <div
                            style={{
                              backgroundColor: "#825d37",
                              padding: 4,
                              color: "white",
                            }}
                          >
                            {item.qty}
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              Increment(item);
                              dispatch(addProducts(item));
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {data && data.length > 0 ? (
                <div
                  className="row-md-6 card"
                  type="button"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: 4,
                    backgroundColor: "#825d37",
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    min_order > countData
                      ? showToastErr("Minimum order should be 500 Rs.")
                      : setAddressSet(true);
                    getCustomerAddresses();
                  }}
                >
                  <div className="col-md-6" style={{ marginLeft: 40 }}>
                    <p className="fw-bold pTag" style={{ color: "white" }}>
                      ₹{countData}
                    </p>
                    <p className="fw-bold pTag" style={{ color: "white" }}>
                      {"Total"}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="fw-bold pTag" style={{ color: "white" }}>
                      {" Proceed To Pay"}
                    </p>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {/* this is My Address code */}
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                  My Address
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setAddressSet(false);
                    handaleInput();
                  }}
                ></button>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setAdd_Address(true);
                  setAddressSet(false);
                  handaleInput();
                }}
              >
                + Add Address
              </button>
              <div
                className="offcanvas-body"
                style={{
                  marginBottom: 50,
                }}
              >
                {addressData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        item.id === addressId
                          ? "row-md-4 card addressSelect"
                          : "row-md-4 card address"
                      }
                      onClick={() => {
                        setAddressID(item.id);
                        Cookies.set("user_address", JSON.stringify(item));
                      }}
                    >
                      <div
                        className="col-md-12 "
                        style={{
                          //  textAlign: "center"
                          marginLeft: 12,
                        }}
                      >
                        <p className="pTag">{item.contact_person}</p>
                        <p className="pTag">{item.contact_no}</p>

                        <p className="pTag m-10">
                          {item.city},{item.house_no},{item.locality},
                          {item.pincode},{item.state}
                        </p>
                      </div>
                      {/* <div
                        className="col-md-8"
                        style={{ marginLeft: "4px", marginRight: "4px" }}
                      ></div> */}
                      {/* <div className="col-md-4" style={{ textAlign: "center" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginRight: 4 }}
                          onClick={() => {
                            console.log("item response :::::::", item.id);
                            handaleEditeAddress(item);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: 4 }}
                          onClick={() => {
                            deleteCustomerAddress(item.id);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <Link className="my-nav-link" to={`/checkout/${addressId}`}>
                <div
                  className="row-md-6 card"
                  type="button"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: 4,
                    backgroundColor: "#825d37",
                    marginBottom: 10,
                    padding: 10,
                  }}
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setAddressSet(false);
                  }}
                >
                  <p className="fw-bold pTag" style={{ color: "white" }}>
                    {"Done"}
                  </p>
                </div>
              </Link>
            </>
          )}
        </>
      ) : (
        <>
          {/* this is Add Address code */}
          {update_Address === true ? (
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                Update Address
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  setAdd_Address(false);
                  setAddressSet(true);
                  setUpdate_Address(false);
                  handaleInput();
                }}
              ></button>
            </div>
          ) : (
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                Add Address
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  setAdd_Address(false);
                  setAddressSet(true);
                }}
              ></button>
            </div>
          )}

          <div
            className="offcanvas-body"
            style={{
              marginBottom: 50,
            }}
          >
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={name ? name : ""}
                  className="form-control"
                  placeholder="First name"
                  aria-label="First name"
                  id="userName"
                  required
                  onChange={nameInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="number"
                  className="form-control"
                  defaultValue={phoneNumber ? phoneNumber : ""}
                  placeholder="Phone Number"
                  aria-label="Phone number"
                  required
                  onChange={phoneInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 10) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={houseNumber ? houseNumber : ""}
                  placeholder="House Number"
                  aria-label="House Number"
                  required
                  onChange={houseInputChange}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={locality ? locality : ""}
                  placeholder="Near About"
                  aria-label="Near About"
                  required
                  onChange={nearInputChange}
                />
              </div>

              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={city ? city : ""}
                  placeholder="City"
                  aria-label="City"
                  required
                  onChange={cityInputChange}
                />
              </div>

              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={zip ? zip : ""}
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  defaultValue={state ? state : ""}
                  aria-label="State"
                  required
                  onChange={stateInputChange}
                />
              </div>
            </div>
          </div>
          {update_Address === true ? (
            <div
              className="row-md-6 card"
              type="button"
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 4,
                backgroundColor: "#825d37",
                marginBottom: 10,
                padding: 10,
              }}
              onClick={() => {
                updateCustomerAddress();
              }}
            >
              <p className="fw-bold pTag" style={{ color: "white" }}>
                {"Update"}
              </p>
            </div>
          ) : (
            <div
              className="row-md-6 card"
              type="button"
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 4,
                backgroundColor: "#825d37",
                marginBottom: 10,
                padding: 10,
              }}
              onClick={() => {
                addCustomerAddress();
              }}
            >
              <p className="fw-bold pTag" style={{ color: "white" }}>
                {"Add"}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Cart;

import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="row g-2  text-start">
          <div className="col-md-3">
            <div>
              <h6 className="textColor">Contact Us</h6>
            </div>
            <div>
              <span className="texthover LinkColor" style={{ margin: 1 }}>
                26, Central Market, Lajpat Nagar II, Lajpat Nagar, New Delhi
                Delhi 110024
              </span>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="mailto:zayadryfruits@gmail.com"
              >
                <i className="fa-brands fa-telegram textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  info@zayadryfruits.com
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="tel:+9199583 44409"
              >
                <i className="fa-brands fa-skype textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  +(91) 99583 44409
                </span>
              </Link>
            </div>
          <hr className="hrTag"/>
          </div>
          <div className="col-md-3">
            <div>
              <h6 className="textColor">Useful Links</h6>
            </div>
            <div>
              <Link className="text-decoration-none LinkColor" to="/#home">
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Home
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="/about#about-us"
              >
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  About us
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="/contact#contact-us"
              >
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Contact us
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="/shipping&refundPolicy#shipping&refund-policy"
              >
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Shipping & Refund Policy
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="/term&Condition#term-condition"
              >
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Terms & Condition
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="/privacy-policy#privacy-policy"
              >
                <span className="texthover LinkColor" style={{ margin: 1 }}>
                  Privacy Policy
                </span>
              </Link>
            </div>
          <hr className="hrTag"/>
          </div>
          <div className="col-md-3">
            <div>
              <h6 className="textColor">Follow Us Now</h6>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="https://www.facebook.com/ZayaDryFruits"
              >
                <i className="fa-brands fa-facebook-f textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Facebook
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="https://twitter.com/ZayaDryFruits"
              >
                <i className="fa-brands fa-twitter textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Twitter
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="https://www.instagram.com/zayadryfruits/"
              >
                <i className="fa-brands fa-instagram textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Instagram
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="https://in.pinterest.com/ZAYADryFruits/"
              >
                <i className="fa-brands fa-pinterest-p textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Pinterest
                </span>
              </Link>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to="https://www.linkedin.com/company/zaya-dryfruits/"
              >
                <i className="fa-brands fa-linkedin-in textColor"></i>
                <span
                  className="texthover LinkColor"
                  style={{ marginLeft: 10 }}
                >
                  Linkedin
                </span>
              </Link>
            </div>
          <hr className="hrTag"/>
          </div>
          <div className="col-md-3">
            <div>
              <h6 className="textColor">For Complete Menu</h6>
            </div>
            <div>
              <Link
                className="text-decoration-none LinkColor"
                to={`/Khari-Baoli-Dry-Fruits-Rate-List-Today`}
              >
                <span className="texthover LinkColor">
                  Click To Download Rate List
                </span>
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div className="text-center">
          <i className="fa-regular fa-copyright"></i>
          <span className="LinkColor">
            2023 Zaya Dryfruits By{" "}
            <Link
              className="text-decoration-none LinkColor"
              to="https://www.beaconcoders.com/"
              target="_blank"
            >
              Beacon Coders .
            </Link>
            All Rights Reserved.
          </span>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Please Login
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                You must login first to add to cart
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <Link className="nav-link " to="/login">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </footer>
  );
}

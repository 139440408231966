import React, { useState } from "react";
import Orders from "../MyOrders/order";
import NewAddress from "../MyAddress/newAddress";
import Wallet from "../MyWallet/wallet";
import ProfilList from "../component/profileList";
import ProfileDetails from "../component/ProfileDetails";
import { Helmet } from "react-helmet";
const Profile = () => {
  const [selectTab, setSelectTab] = useState(0);

  const handleSelectSubcategory = (subcategoryId) => {
    setSelectTab(subcategoryId);
  };
  return (
    <div className="container topMargin row horizontalContainer">
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {/* <div className="row"> */}
      <div className="col-md-4 my-4">
        <ProfilList onSelectSubcategory={handleSelectSubcategory} />
      </div>
      <div className="col-md-8">
        {
          selectTab == 0 ? (
            <ProfileDetails />
          ) : selectTab == 1 ? (
            <NewAddress />
          ) : (
            <Orders />
          )
          //  : (
          //   <Wallet />
          // )
        }
      </div>
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const NewAddress = () => {
  const [Token, setToken] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [addressId, setAddressID] = useState("");
  const [name, setName] = useState("");
  const [phone_No, setPhone_no] = useState("");
  const [house_no, setHouse_no] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [idData, setIdData] = useState("");
  const [deleteAddress, setDeleteAddress] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        getCustomerAddresses(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // Get All Customer Address    -------------
  const getCustomerAddresses = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCustomerAddresses`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setAddressData(userData.data);
        setAddressID(userData.data[0].id);
        console.log("userData::::::::", userData);
      })
      .catch((error) => console.log("error", error));
  };

  // Delete Customer Address    -------------
  const deleteCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", deleteAddress);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/deleteCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        console.log("userData:::::::::", userData);
        getCustomerAddresses(Token);
        showToast(userData.msg);
      })
      .catch((error) => console.log("error", error));
  };

  //Add Customer Address    ---------------
  const addCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("house_no", house_no);
    formdata.append("locality", locality);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    formdata.append("contact_person", name);
    formdata.append("contact_no", phone_No);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/addCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        console.log("userData::::::::::", userData);
        if (userData.error === false) {
          getCustomerAddresses(Token);
          handaleEditeAddressClean();
        }
        const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((error) => console.log("error", error));
  };

  // Update Customer Address    -------------
  const updateCustomerAddress = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", idData);
    formdata.append("house_no", house_no);
    formdata.append("locality", locality);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    formdata.append("contact_person", name);
    formdata.append("contact_no", phone_No);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/updateCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.error == false) {
          getCustomerAddresses(Token);
        }
        const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((error) => console.log("error", error));
  };

  function getLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Latitude:::::::::::", latitude);
          console.log("Longitude:::::::::::", longitude);

          // Call a function to decode coordinates and get the address
          decodeCoordinates(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  async function decodeCoordinates(latitude, longitude) {
    const apiKey = "AIzaSyA-qZsb3fO1mYAVhG5hEmuMQ7Cc8zWqDlU";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const address = data.results[0].formatted_address;
        const addressComponent = data.results[0].address_components;
        const nearAbout = data.results[0].formatted_address
          .split(", ")
          .slice(0, 3);
        setLocality(`${nearAbout[0]} , ${nearAbout[1]} , ${nearAbout[2]}`);
        for (const component of addressComponent) {
          const types = component.types;
          if (types.includes("locality")) {
            setCity(component.long_name);
          } else if (types.includes("administrative_area_level_1")) {
            setState(component.long_name);
          } else if (types.includes("postal_code")) {
            setPincode(component.long_name);
          }
        }
        console.log("Address:", address);
      } else {
        console.error("No address found for these coordinates.");
      }
    } catch (error) {
      console.error("Error decoding coordinates:", error);
    }
  }

  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhone_no(inputPhoneNumber);
    }
  };
  const houseInputChange = (e) => {
    setHouse_no(e.target.value);
  };
  const nearAboutInputChange = (e) => {
    setLocality(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setPincode(inputPhoneNumber);
    }
  };

  // Clean All Input Field  Add & Update api
  const handaleEditeAddressClean = (e) => {
    setIdData("");
    setName("");
    setPhone_no("");
    setHouse_no("");
    setLocality("");
    setCity("");
    setState("");
    setPincode("");
  };

  // Fill All Input Field To Use Update  Customer Address
  const handaleEditeAddress = (e) => {
    setIdData(e.id);
    setName(e.contact_person);
    setPhone_no(e.contact_no);
    setHouse_no(e.house_no);
    setLocality(e.locality);
    setCity(e.city);
    setState(e.state);
    setPincode(e.pincode);
  };

  return (
    <div className="container">
      <Helmet>
        <title>Address</title>
      </Helmet>
      <div
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        className="AddAddress my-2"
      >
        + Add Address
      </div>
      {addressData.map((item, index) => {
        return (
          <div
            key={index}
            className={
              item.id === addressId
                ? "row-md-4 card addressSelect p-3"
                : "row-md-4 card address p-3 my-2"
            }
            onClick={() => {
              setAddressID(item.id);
              Cookies.set("user_address", JSON.stringify(item));
            }}
          >
            <div className="col-md-4 " style={{ textAlign: "center" }}>
              <p className="pTag">{item.contact_person}</p>
              <p className="pTag">{item.contact_no}</p>
            </div>
            <div
              className="col-md-4"
              style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              {item.city},{item.house_no},{item.locality},{item.pincode},
              {item.state}
            </div>
            <div className="col-md-4" style={{ textAlign: "center" }}>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#EditAddress"
                className="btn  btn-primary my-2"
                style={{ marginRight: 4 }}
                onClick={() => {
                  handaleEditeAddress(item);
                }}
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#DeleteAddress"
                style={{ marginLeft: 4 }}
                onClick={() => {
                  setDeleteAddress(item?.id);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        );
      })}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="First name"
                    required
                    value={name}
                    onChange={nameInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    aria-label="Last name"
                    required
                    value={phone_No}
                    onChange={phoneInputChange}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 10) {
                        event.preventDefault(); // Prevent entering more characters
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row  my-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House Number"
                    aria-label="First name"
                    required
                    value={house_no}
                    onChange={houseInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Near About"
                    aria-label="Last name"
                    required
                    value={locality}
                    onChange={nearAboutInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    aria-label="City"
                    required
                    value={city}
                    onChange={cityInputChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-label="State"
                    required
                    value={state}
                    onChange={stateInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-5 my-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  value={pincode}
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  getLocation();
                }}
              >
                <i className="fa-solid fa-location-crosshairs"></i> Use My Location
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  addCustomerAddress();
                }}
                data-bs-dismiss="modal"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditAddress"
        tabIndex="-1"
        aria-labelledby="EditAddressLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditAddressLabel">
                Edit Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="First name"
                    required
                    value={name}
                    onChange={nameInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    aria-label="Last name"
                    required
                    value={phone_No}
                    onChange={phoneInputChange}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 10) {
                        event.preventDefault(); // Prevent entering more characters
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row  my-4">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House Number"
                    aria-label="First name"
                    required
                    value={house_no}
                    onChange={houseInputChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Near About"
                    aria-label="Last name"
                    required
                    value={locality}
                    onChange={nearAboutInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    aria-label="City"
                    required
                    value={city}
                    onChange={cityInputChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-label="State"
                    required
                    value={state}
                    onChange={stateInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-5 my-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Zip"
                  aria-label="Zip"
                  required
                  value={pincode}
                  onChange={zipInputChange}
                  onKeyPress={(event) => {
                    if (event.target.value.length >= 6) {
                      event.preventDefault(); // Prevent entering more characters
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  updateCustomerAddress();
                }}
                data-bs-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="DeleteAddress"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              Are you sure ? You want delete address !
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCustomerAddress();
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAddress;

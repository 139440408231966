import React, { useState, useEffect } from "react";
import { URL, ImgURL } from "../url/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import ActivityIndicator from "../component/ActivityIndicator";
import { Helmet } from "react-helmet";
import blankSearch from "../asset/jpgImage/blankSearch.jpeg";
import almond from "../asset/pngImage/almond.png";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("q");
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataVariant, setDataVariant] = useState("");
  const [SearchData, setSearchData] = useState(searchTerm);
  const [page, setPage] = useState(1);
  const currentPageUrl = window.location.href;
  // const searchTerm = currentURL.split("/");
  const [Token, setToken] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        setIsLoggedIn(true);
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  useEffect(() => {
    searchProduct();
  }, [searchTerm]);
  useEffect(() => {
    scrollHandel();
  }, [page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Fetch initial data when the component mounts

  const searchProduct = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}/searchProduct/${SearchData}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setData(newData?.data?.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight // Adjust this threshold as needed
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.log("error::::::", error);
    }
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };

  const scrollHandel = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}/searchProduct/${SearchData}?page=${page}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setData((prev) => [...prev, ...newData?.data?.data]);
      })
      .catch((error) => console.log("error", error));
  };

  const handleInputChange = (e) => {
    setSearchData(e.target.value);
  };
  // Add item in the cart
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(id));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        showToast(newData.msg);
        if (newData.error === false) {
          getCart();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        {
          newData.data.cart.map((item) => {
            dispatch(refreshCart(item));
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // if (loading) {
  //   return <ActivityIndicator />;
  // }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Handle the "Enter" key press here
      navigate(`/search?q=${encodeURIComponent(SearchData)}`);
    }
  };
  return (
    <div className="Container" style={{ marginTop: 75 }}>
      <Helmet>
        <title>Search</title>
        <meta property="og:title" content={"Search"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <form
        className="d-flex"
        role="search"
        style={{
          marginRight: "20%",
          marginLeft: "20%",
          marginTop: "7%",
          marginBottom: "1%",
        }}
      >
        <input
          className="form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
          id="search"
          value={SearchData}
          onChange={(e) => setSearchData(e.target.value)}
          onKeyPress={handleKeyPress}
          // onBlur={handleDateBlur}
          style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (searchTerm) {
              searchProduct();
            }
          }}
          style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </form>
      <div className="row container-fluid m-0">
        {data.length > 0 ? (
          data.map((item) => {
            let calculate =
              (item?.variants[0]?.sp / item?.variants[0]?.mrp) * 100;
            let Percentage = 100 - calculate;
            let name = item?.name;
            const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
            return (
              <div className="col-md-2 p-1 col-6">
                <div className="card h-100">
                  <Link
                    className="my-nav-link"
                    to={`/product/${item.id}/${product}`}
                  >
                    <div>
                      {item?.variants[0]?.sp !== item?.variants[0]?.mrp ? (
                        <div
                          style={{
                            height: 40,
                            width: 40,
                            position: "absolute",
                            backgroundColor: "#538cee",
                            left: 4,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                          }}
                        >
                          <p
                            className="fw-bold"
                            style={{
                              fontSize: 10,
                              color: "white",
                              textAlign: "center",
                            }}
                          >
                            {Percentage.toFixed(2)}% Off
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <img
                      key={item.id}
                      alt={item.name}
                      src={`${ImgURL}${item.img}`}
                      loading="lazy"
                      onError={handleImageError}
                      className="card-img-top mx-auto"
                      style={{
                        height: "145px",
                        resizeMode: "contain",
                        width: "90%",
                        marginTop: 5,
                      }}
                    />
                    <div
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 2,
                        textAlign: "center",
                        paddingTop: 10,
                      }}
                    >
                      <p
                        className="fw-bold"
                        style={{ fontSize: 12, fontWeight: "500" }}
                      >
                        {/* {item.name.length > 20
                      ? item.name.substring(0, 20) + "..."
                      : item.name} */}
                        {item.name}
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p className="fw-bold" style={{ fontSize: 11 }}>
                        {item?.variants[0]?.size}
                      </p>
                    </div>
                  </Link>
                  <>
                    {item?.variants[0]?.sp === item?.variants[0]?.mrp ? (
                      <div
                        className="d-flex justify-content-evenly"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ paddingTop: 10 }}>
                          <p
                            className="fw-bold"
                            style={{ fontSize: 11, margin: 0 }}
                          >
                            ₹{item?.variants[0]?.sp}
                          </p>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#variantsModal"
                            onClick={() => {
                              setDataVariant(item);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-evenly">
                        <div>
                          <p
                            className="fw-bold"
                            style={{ fontSize: 11, margin: 0 }}
                          >
                            ₹{item?.variants[0]?.sp}
                          </p>
                          <p style={{ fontSize: 10 }}>
                            <del>₹{item.variants[0].mrp}</del>
                          </p>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#variantsModal"
                            onClick={() => {
                              setDataVariant(item);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              alt="..."
              src={blankSearch}
              loading="lazy"
              className="card-img-top mx-auto"
              style={{
                height: "245px",
                resizeMode: "contain",
                width: "245px",
                marginTop: 5,
              }}
            />
            <h4 style={{ marginTop: 10, marginBottom: 40 }}>
              No match product found !
            </h4>
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="variantsModal"
        tabIndex="-1"
        aria-labelledby="variantsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="variantsModalLabel">
                {dataVariant.name}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {dataVariant ? (
                <>
                  {dataVariant.variants.map((value) => {
                    return (
                      <div
                        className="container d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: 6,
                        }}
                      >
                        {value?.mrp === value?.sp ? (
                          <>
                            <div>{value.size}</div>
                            <div className="d-flex">₹{value?.sp}</div>
                            {!isLoggedIn ? (
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                data-bs-target="#variantsModal"
                                onClick={() => {
                                  updateCart(value.id);
                                }}
                                data-bs-toggle="modal"
                              >
                                Add
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {}}
                              >
                                Add
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <div>{value.size}</div>
                            <div className="d-flex">
                              <del style={{ marginRight: 4 }}>₹{value.mrp}</del>
                              ₹{value?.sp}
                            </div>
                            {!isLoggedIn ? (
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                data-bs-target="#variantsModal"
                                onClick={() => {
                                  updateCart(value.id);
                                }}
                                data-bs-toggle="modal"
                              >
                                Add
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Add
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;

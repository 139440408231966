import React from "react";
import walnut from "../asset/pngImage/walnut.png";
import { ImgURL } from "../url/api";
import { Helmet } from "react-helmet";
import almond from "../asset/pngImage/almond.png";

const subCategoriesProduct = ({
  data,
  onSelectSubcategory,
  selectSubCategoryId,
}) => {
  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  const currentPageUrl = window.location.href;
  return (
    <div className="card width: 15rem" style={{ width: "100%" }}>
      {data?.subcategory?.map((item) => {
        let name = item.name;
        const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
        return (
          <ul key={item.id} className="list-group list-group-flush">
            <Helmet>
              <title>{item?.name}</title>
              <meta name="Description" content={item?.description} />
              <meta name="KeyWords" content="DryFruits, Nuts, Pulses" />
              <meta property="og:title" content={item?.name} />
              <meta property="og:description" content={item?.description} />
              <meta property="og:url" content={currentPageUrl} />
              <meta property="og:site_name" content="Zaya Dryfruits" />
              <meta property="og:image" content={`${ImgURL}${item.img}`} />

              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
              ></script>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
              ></script>
              <script>
                {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag() {
                            dataLayer.push(arguments);
                          }
                          gtag('js', new Date());
                          gtag('config', 'G-8EXFWPNHC8');
                        `}
              </script>
              <script>
                {`{ window.dataLayer = window.dataLayer || [];
                          function gtag() {
                              dataLayer.push(arguments);
                          }
                          gtag('js', new Date());

                          gtag('config', 'UA-180675332-2');}`}
              </script>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
              ></script>
              <script>
                {`{  window.dataLayer = window.dataLayer || [];
                          function gtag() {
                              dataLayer.push(arguments);
                          }
                          gtag('js', new Date());

                          gtag('config', 'AW-353927359');}`}
              </script>
            </Helmet>
            <li
              className={"list-group-item textColor"}
              role="button"
              onClick={() => {  
                onSelectSubcategory(item);
              }}
              style={{
                backgroundColor:
                  item.id == selectSubCategoryId ? "#538cee" : "#ffffff",
              }}
            >
              <img
                src={item.img == null ? walnut : `${ImgURL}${item.img}`}
                alt={item.name}
                onError={handleImageError}
                loading="lazy"
                style={{ height: 35, width: 35 }}
              />
              <p
                className="texthover m-1"
                style={{
                  fontSize: 12,
                  fontWeight:'500',
                  color:
                    item.id == selectSubCategoryId ? " #ffffff" : "#825d37",
                }}
              >
                {item.name}
              </p>
            </li>
          </ul>
        );
      })}
    </div>
  );
};

export default subCategoriesProduct;

import React from "react";

function CompanyDetails() {
  const imageUrlForWeb =
    "https://zaya.beaconlearninghub.com/admin/images/sliders/1692253380.jpeg";
  const imageUrlForMobile =
    "https://zaya.beaconlearninghub.com/admin/images/sliders/1692258864.png";

  const determineImageUrl = () => {
    // You can adjust the breakpoint value based on your design requirements
    const breakpoint = 600; // For example, consider screens narrower than 600px as mobile

    // Check the viewport width
    const isMobile = window.innerWidth < breakpoint;

    // Return the appropriate image URL
    return isMobile ? imageUrlForMobile : imageUrlForWeb;
  };
  return (
    <div className="px-4 text-center">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="p-3">
            <p className="textColor">WE ARE ZAYA DRYFRUITS</p>
            <h2 className="textColor">The Best Trusted Company</h2>
            <p style={{ textAlign: "center" }}>
              ZAYA DRYFRUITS, leading the way in developing extraordinary
              products from quality ingredients, getting from diverse
              industries, has ability to respond to changing consumer needs and
              retain their competitive edge, locally and globally. Our expertise
              in this area is vast – from dry fruits, nuts, pulses and spices
              (masale), biscuits, baked snacks and dried fruits.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3">
            <img
              src="https://zaya.beaconlearninghub.com/admin/footer_banner/banner2.jpeg"
              alt="zayadryfruits"
              loading="lazy"
              style={{ height: 300, width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div>
        <img
          src={determineImageUrl()}
          alt="zayadryfruits"
          loading="lazy"
          style={{ height: '90%', width: "100%" }}
        />
      </div>
    </div>
  );
}
export default CompanyDetails;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { URL, ImgURL } from "../url/api";
import ActivityIndicator from "../component/ActivityIndicator";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import almond from "../asset/pngImage/almond.png";
function OrderPlaced() {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentPageUrl = window.location.href;
  const [newData, setNewData] = useState("");
  const [loading, setLoading] = useState(false);
  const [Token, setToken] = useState("");
  useEffect(() => {
    checkLogin();
  }, [id]);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(JSON.parse(session));
        getOrderDetails(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const getOrderDetails = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getOrderDetails/${id} `, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result).data;
        setNewData(newData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error getOrderDetails", error);
      });
  };
  const cancelOrder = (id) => {
    console.log("press;;;:", id);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/cancelOrder/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          showToast(newData.msg);
          getOrderDetails(Token);
        } else {
          showToastErr(newData.msg);
        }
      })
      .catch((error) => console.log("cancelOrder error", error));
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };

  const getPaymentGatewayKey = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getPaymentGatewayKey`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        console.log("getPaymentGatewayKey ::::", newData.data);
        handlePayment(newData.data);
      })
      .catch((error) => console.log("error", error));
  };
  const handlePayment = async (razorPay_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK faild to load. Are you online ?");
      return;
    }
    var options = {
      key: razorPay_id, // Enter the Key ID generated from the Dashboardcurrency: "INR",
      name: "Zaya Dry Fruits", //your business name
      description: "Test Transaction",
      image: "https://zaya.beaconlearninghub.com/assets/img/zaya-logo.png",
      order_id: newData?.pg_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      // prefill: {
      //   //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      //   name: "Gaurav Kumar", //your customer's name
      //   email: "gaurav.kumar@example.com",
      //   contact: "9000090000", //Provide the customer's phone number for better conversion rates
      // },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      handler: (response) => {
        console.log("response::::::::", response);
        // Handle the payment response
        if (response.razorpay_payment_id) {
          // Payment successful
          checkPayment();
          console.log("Payment Successful", response.razorpay_payment_id);
          // You can perform additional actions here, such as updating the UI or sending a confirmation email
        } else {
          // Payment failed or cancelled
          console.log("Payment Failed");
          // You can show an error message or perform any other necessary actions
        }
      },
    };

    const paymentInstance = new window.Razorpay(options);
    paymentInstance.open();
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      // script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.src = src;
      // script.async = true;
      script.onload = () => {
        // Razorpay script has loaded, you can now create payment instances
        resolve(true);
      };
      script.onerror = () => {
        // Razorpay script has loaded, you can now create payment instances
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const checkPayment = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}/checkPayment/${newData?.id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          showToast(newData.msg);
          getOrderDetails(Token);
        } else {
          showToastErr(newData.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="topMargin container">
      <Helmet>
        <title>Order Placed</title>
        {/* <meta name="Description" content={data?.description} /> */}
        <meta name="KeyWords" content="DryFruits, Nuts, Pulses" />
        <meta property="og:title" content={"Order Placed"} />
        {/* <meta property="og:description" content={data?.description} /> */}
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
        {/* <meta property="og:image" content={`${ImgURL}${data.img}`} /> */}
      </Helmet>
      <div className="row">
        <div className="col-md-7 my-3">
          {newData?.items?.map((item) => {
            return (
              <div
                className="row-md-4 card "
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 4,
                }}
              >
                <div className="col-md-4 " style={{ textAlign: "center" }}>
                  <img
                    key={item.id}
                    alt={item?.name}
                    src={`${ImgURL}${item.img}`}
                    loading="lazy"
                    onError={handleImageError}
                    className="card-img-top mx-auto"
                    style={{
                      height: "100px",
                      resizeMode: "cover",
                      width: "100px",
                      margin: 2,
                    }}
                  />
                </div>

                <div
                  className="col-md-7"
                  style={{ marginLeft: "4px", marginRight: "4px" }}
                >
                  <Link
                    className="my-nav-link"
                    to={`/product/${item.product_id}/${item?.name}`}
                    // onClick={()=>{
                    //   console.log('item press::::::', item);
                    // }}
                  >
                    <p className="pTag">{item.name}</p>
                    {/* <p>{item.size}</p> */}
                    {item.mrp !== item.sp ? (
                      <p className="pTag">
                        <del>₹{item.mrp}</del>₹{item.sp}
                      </p>
                    ) : (
                      <p className="pTag">₹{item.sp}</p>
                    )}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="card col-md-5 my-4">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h5 className="my-2">#id: {newData.id}</h5>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#CancelOrder"
              style={{ marginTop: 10 }}
            >
              Cancel Order
            </button>
          </div>
          <h6 className="my-2">Address Details</h6>
          <p className="pTag">{newData.contact_person}</p>
          <p className="pTag">{newData.contact_no}</p>
          <p className="pTag">
            {newData.house_no},{newData.locality}, {newData.city}
          </p>
          <p className="pTag">
            {newData.pincode},{newData.state}
          </p>
          <hr />
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p>Total</p>
            <p>{newData.sub_total}</p>
          </div>
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p>Delivery charges</p>
            <p>{newData.delivery_charges}</p>
          </div>
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p>Order amount</p>
            <p>{newData.order_total}</p>
          </div>
          <hr />
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p>Pay amount</p>
            <p>{newData.payable_amt}</p>
          </div>
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p> Pay mode: </p>
            <p>{newData.pay_mode}</p>
          </div>
          <div
            className="horizontalContainer"
            style={{ justifyContent: "space-between" }}
          >
            <p> Delivery status: </p>
            <p>{newData.status}</p>
          </div>
          {newData.is_paid == "0" &&
          newData.pay_mode === "online" &&
          newData.status === "pending" ? (
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: 10 }}
              onClick={() => {
                getPaymentGatewayKey();
              }}
            >
              Pay Again
            </button>
          ) : null}
          <hr />
          {newData.order_notes && (
            <div>
              <p>{newData.order_notes}</p>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="CancelOrder"
        tabIndex="-1"
        aria-labelledby="CancelOrderLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="CancelOrderLabel">
                Cancel Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              Are you sure ? You want Cancel Order !
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  cancelOrder(newData.id);
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced;

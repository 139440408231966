import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const RedirectOldProductUrl = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  // Extract parameters from the old URL
  const id = searchParams.get("id");
  const productName = searchParams.get("product_name");

  useEffect(() => {
    // Check if parameters exist and redirect to the new URL
    if (id && productName) {
      navigate(`/product/${id}/${productName}`);
    }
  }, [id, productName, navigate]);

  // Render nothing or a message if needed
  return null;
};

export default RedirectOldProductUrl;

import React, { useState, useEffect, useRef } from "react";
import { URL, ImgURL } from "../url/api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { Link } from "react-router-dom";
import ActivityIndicator from "./ActivityIndicator";
import walnut from "../asset/pngImage/walnut.png";

const ImageSlider = ({ images }) => {
  const [bannerData, setBannerData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState(null);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        fetch(`${URL}/getDashboard`, {
          method: "GET",
          redirect: "follow",
        })
          .then((res) => res.text())
          .then((response) => {
            const result = JSON.parse(response);
            const sliderArray = result.data.sliders.map((slide) => {
              return slide.image;
            });
            setLoading(false);
            setBannerData(sliderArray);
            setData(result.data.categories);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleImageError = (event) => {
    event.target.src = walnut; // Use the logo variable from your imports
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
      >
        <div className="carousel-inner">
          {bannerData.map((imageUrl, index) => {
            return (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <img
                  src={`${ImgURL}${imageUrl}`}
                  alt={`Banner ${index + 1}`}
                  loading="lazy"
                  className="d-block w-100"
                />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <h1
        className="textColor"
        style={{
          fontSize: 26,
          marginVertical: 10,
          marginTop: 10,
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        The Best DryFruits, Nuts, Pulses and Masale Online Shop
      </h1>
      <div className="slider-button">
        <button className="swiper-button-next" onClick={handlePrev}>
          <i className="fa-solid fa-angles-left"></i>
        </button>
        <button className="swiper-button-prev" onClick={handleNext}>
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {data.map((item) => {
          let name = item.name;
          const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
          let subCategoryName = item?.subcategory[0]?.name;
          const subCategoryProduct = subCategoryName
            ? subCategoryName.replace(/[\s()]+/g, "-").replace(/-+$/, "")
            : "";
          return (
            <SwiperSlide key={item.id}>
              <Link
                className="nav-link"
                aria-current="page"
                to={`/category/${item.id}/${product}/${item?.subcategory[0]?.id}/${subCategoryProduct}`}
              >
                <img
                  key={item.id}
                  loading="lazy"
                  className="image-hover"
                  src={`${ImgURL}${item.img}`}
                  alt={item.name + "Image"}
                  onError={handleImageError}
                  style={{
                    height: 170,
                    width: "95%",
                    margin: 10,
                    resizeMode: "contain",
                    borderRadius: 10,
                  }}
                />
                <div style={{ textAlign: "center" }}>
                  <h6>{item.name}</h6>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default ImageSlider;
